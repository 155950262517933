
const user = {
    state() {
      return {
        user: null
      }
    },
    getters: {
      getUser(state) { return state.user }
    },
    mutations: {
      setUser(state, i) {
        state.user = i
      }
    },
    actions: {
        setUser({commit}, i) {
            return new Promise((resolve) => {
                commit("setUser", i)
                resolve()
            })
      }
    }
  }
export default user