// we define our routes in this file
const routes = [
  {
    path: '/',
    component: () => import('../layouts/default.vue'),
    children: [
      { path: '', name: '', component: () => import("../components/HelloWorld.vue"), meta: { requiresAuth: false, permissions: [] } },
      { path: '/login', name: '',
        component: () => import("../pages/Login.vue"),
        meta: {
            requiresAuth: false,
            permissions: []
        }
      },
      {
        path: '/new_user',
        name: '',
        component: () =>
            import("../pages/NewUser.vue"),
        meta: {
            requiresAuth: false,
            permissions: []
        }
      },
      {
        path: '/game/:id',
        name: '',
        component: () =>
            import("../pages/Game.vue"),
        meta: {
            requiresAuth: false,
            permissions: []
        }
      },
    ]
  },
  {
    path: '/admin',
    name: '',
    component: () =>
        import("../layouts/admin.vue"),
    meta: { requiresAuth: true, permissions: []},
    children: [
      { path: '/admin', name: 'Admin', component: () => import("../pages/Admin.vue")},
      { path: '/filemanager', name: 'FileManager', component: () => import("../pages/FileManager.vue") }
    ]
  }
]

export default routes