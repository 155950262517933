import { createStore } from 'vuex'

import user from "./user"

const store = createStore({
    namespaced: false,
    modules: {
        user
    },
    strict: false
})


export default store