
import './styles/quasar.sass'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css'
import '@quasar/extras/mdi-v7/mdi-v7.css'
import Notify from 'quasar/src/plugins/Notify.js';import Cookies from 'quasar/src/plugins/Cookies.js';;

// To be used on app.use(Quasar, { ... })
export default {
  
  config: {
    extras: [
      'material-icons-outlined',
      'material-icons',
      'mdi-v6',
    ],
  },
  plugins: { Notify, Cookies },
  boot: ['axios']
}