// router/index.js

import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'


/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

const router = createRouter({
    history: createWebHistory(),
    routes,
});

/**
 * Run the middleware(s) using the beforeEach hook
 */
router.beforeEach((to, from, next) => {
    var resolveResult = router.resolve(to.path);
    if (resolveResult.matched.length == 0) {
        console.log("not found page")
    }
    return next()
    /*if (!to.meta.middlewares) return next()
    let middlewares = to.meta.middlewares
    let context = { to, from, next, store }
    return middlewares[0]({
        ...context,
        next: middlewarePipeline(context, middlewares, 1)
    })*/
})

export default router